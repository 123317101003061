import React from 'react';

import { useIdentity } from '../hooks';
import CompanyEmailDomainTable from '../components/CompanyEmailDomainTable';

const AutoDKIM = () => {
  const { company_id, company_type } = useIdentity();
  return <CompanyEmailDomainTable company_id={company_id} company_type={company_type}/>;
};

export default AutoDKIM;